import React from "react";
import { Link } from "react-router-dom";
import Rating from "react-rating-stars-component";

const ThankYou = () => {
  return (

    <section className="hero-section-bar py-5">
        <div className="container">
          <div className="row align-items-center">
            {/* Text on the left side */}
            <div className="col-md-12 text-center my-5 mt-0">
              <div className="text-container">
                <Link to={"/"} className="navbar-brand">
                  <img
                    className="logo-brands mb-5"
                    src={require("../images/hawk.png")}
                    alt="Your Logo"
                  />
                </Link>
                <div className="w-63 m-auto">
                  <h1 className="py-2  gradient-class">
                  Thank You for {" "}
                    <span className="branding-2 ">Your Purchase!</span>{" "}
                  </h1>
                </div>
                <div class="space-small"></div>
                <div className="w-52 m-auto">
                  <h2 className="py-2">
                 We appreciate your business. Feel free to explore more plans on our website.
                  </h2>
                </div>
                <div class="space-small"></div>

                <div className="links-tab py-2">
                  <Link to={"https://app.hawkglide.com/"} target="_blank" className="animated-button ">
                  Go to Dashboard
                    <img
                      style={{ width: "1.2em", marginLeft: 5, marginTop: -4 }}
                      className="hawk-white"
                      src={require("../images/hawk-white.png")}
                      alt="white logo"
                    />
                  </Link>
                  <p className="pt-2"><span className="gradient-class">7 Days Free Trial.</span> No Credit Card Needed.</p>
                  {/* <Link
                    to={"/"}
                    className="animated-button-2"
                    style={{ marginLeft: 15 }}
                  >
                    Watch Video
                  </Link> */}
                </div>
                {/* Add any other text or components as needed */}
              </div>
            </div>

            {/* Image on the right side */}
            {/* <div className="col-md-6 text-center">
                <div className="image-container">
                <img
                    src={require('../images/top-banner-right.jpg')}
                    alt="Hero Banner Image"
                    className="img-fluid"
                />
                </div>
            </div> */}
          </div>

          <div className="services-title text-center">
            <p className="fs-2 gradient-class font-weight-bold my-2">
              Join Over 1,000 Forward-Thinking Business Owners
            </p>
            <p className="my-1 text-black">
              Who've Transformed their Social Media
            </p>
            <p className="text-black">
              Presence in <strong>Just 180 Days</strong> with Hawk Glide!
            </p>
          </div>
          <div className="row pt-4" style={{ justifyContent: "center" }}>
            <div className="brands-list">
              <div className="branding-logo">
                <img
                  src={require("../images/seccret-room.png")}
                  alt="Product 1"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="branding-logo">
                <img
                  src={require("../images/shubamMotivala.png")}
                  alt="Product 2"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>

              <div className="branding-logo">
                <img
                  src={require("../images/ohstree.png")}
                  alt="Product 3"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="branding-logo">
                <img
                  src={require("../images/seccret-room.png")}
                  alt="Product 1"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="branding-logo">
                <img
                  src={require("../images/shubamMotivala.png")}
                  alt="Product 2"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>

              <div className="branding-logo">
                <img
                  src={require("../images/ohstree.png")}
                  alt="Product 3"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    // <div className="container">
    //   <h1>Thank You for Your Purchase!</h1>
    //   <p>We appreciate your business.</p>
    //   <p>Feel free to explore more products on our website.</p>
    //   <Link to="/">Back to Home</Link>
    // </div>
  );
};

export default ThankYou;
