// About.js

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Rating from "react-rating-stars-component";
import CallToAction from "../components/Call-to-action";

const AboutUS = () => {
  return (
    <div className="about">
      <div className="container">
        <div className="about-us-title py-5">
          <h1 className="title-text branding-1 text-center pt-5">
            <span className="font-weight-bold branding-2 gradient-class">
              Welcome to Hawk Glide
            </span>
            <br /> Where we're committed to revolutionizing social media
            management for businesses of all sizes.
          </h1>
          <img
            src={require("../images/about-banner.png")}
            alt="About Banner"
            className="img-fluid my-4"
          />
          <p className="font-22 text-center w-50 m-auto">
            <em>
              Our mission is to empower users like you to excel in the
              ever-evolving landscape of digital marketing.
            </em>
          </p>
        
        
          <div className="row pt-4">
            <div className="col-md-7">
              <p className="font-22">
                Driven by innovation, we've developed a cutting-edge AI-powered
                platform that simplifies every aspect of social media strategy.
              </p>

              <p className="font-22">
                Whether you're a startup, agency, or enterprise, Hawk Glide is
                designed to cater to your unique needs and help you achieve your
                goals.
              </p>

              <p className="font-22">
                With Hawk Glide, you can bid farewell to time-consuming tasks
                and embrace impactful results. Our intuitive platform automates
                content creation, scheduling, and posting, freeing up your
                valuable time to focus on engaging with your audience and
                driving business growth.
              </p>

              <p className="font-22">
                <strong>
                  We understand the high costs companies often pay to agencies
                  for social media management every month.
                </strong>{" "}
                That's why Hawk Glide offers an affordable alternative without
                compromising on quality or results.
              </p>

              <p className="font-22">
                Backed by advanced AI technology and a dedicated team, Hawk
                Glide is here to support your journey to social media success.{" "}
              </p>

              <p className="font-22">
                Join us as we reshape the future of social media management
                together.
              </p>
            </div>
            <div className="col-md-5">
              <img
                src={require("../images/ceo.jpg")}
                alt="CEO"
                className="img-fluid"
              />
            </div>
          </div>
          </div>

        <section className="call-action-dark py-4 bg-blue-dark rounded-4">
          <div className="text-center p-4">
            <h2 className="title-text text-white text-center">
              <span className="font-weight-bold title-text branding-2 gradient-class">
                An Amazing AI-based Social Media Manager
              </span>
              <br /> is here to support your journey to social media success
            </h2>
            <div className="links-tab py-3 my-4 mb-0">
              <Link
                to={"/pricing"}
                className="animated-button-2"
              >
                <span>Start your 7-Day Free Trial</span>
              </Link>
              <Link
                to={"/pricing"}
                className="animated-button-2"
                style={{ marginLeft: 15 }}
              >
                <span>See Pricing Plans</span>
              </Link>
            </div>
          </div>
        </section>
        <section className="teams-sec py-5">
          <h2 className="title-text text-center branding-1">
            We're proud to have a diverse and talented team dedicated to our
            mission of revolutionizing <b className="gradient-class">AI-Based Social Media Management</b>
          </h2>
          <div className="row mb-3 mt-5">
            <div className="col-md-4 text-center">
              <div className="user-image">
                <img
                  src={require("../images/user-1.jpg")}
                  alt="users"
                  className="img-fluid my-3"
                />
                <p className="text-center mb-0">
                  <strong>Shabnam Palapure</strong>
                </p>
                <p className="text-center">Customer Success Manager</p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="user-image">
                <img
                  src={require("../images/user-2.jpg")}
                  alt="users 2"
                  className="img-fluid my-3"
                />

                <p className="text-center mb-0">
                  <strong>Ammar Jagrala</strong>
                </p>
                <p className="text-center">Lead Developer</p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="user-image">
                <img
                  src={require("../images/user-3.jpg")}
                  alt="users 3"
                  className="img-fluid my-3"
                />

                <p className="text-center mb-0">
                  <strong>Namira Shaikh</strong>
                </p>
                <p className="text-center">Lead Designer</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 text-center">
              <div className="user-image">
                <img
                  src={require("../images/user-1.jpg")}
                  alt="users"
                  className="img-fluid my-3"
                />

                <p className="text-center mb-0">
                  <strong>Nahid Ansari</strong>
                </p>
                <p className="text-center">Cheif Financial Officer</p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="user-image">
                <img
                  src={require("../images/user-2.jpg")}
                  alt="users 2"
                  className="img-fluid my-3"
                />
                <p className="text-center mb-0">
                  <strong>Imran Khan</strong>
                </p>
                <p className="text-center">Lead Developer</p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="user-image">
                <img
                  src={require("../images/user-3.jpg")}
                  alt="users 3"
                  className="img-fluid my-3"
                />

                <p className="text-center mb-0">
                  <strong>Shubham Bane</strong>
                </p>
                <p className="text-center">Lead Developer</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <CallToAction />
    </div>
  );
};

export default AboutUS;
