// Checkout.js

import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from 'react-bootstrap';
import Rating from 'react-rating-stars-component';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify'

import displayRazorpay from "../components/displayRazorpay";


const StarRating = ({ rating }) => {
    return (
      <Rating
        count={5}
        value={rating}
        size={24}
        activeColor="#ffd700"
        isHalf={true}
        edit={false}
      />
    );
  };

const Checkout = () => {

  const navigate = useNavigate();
  const API_URL = 'https://api.hawkglide.com/api/v1';
  // const API_URL = 'http://localhost:5001/api/v1';
  const UI_PREFIXURL = 'http://localhost:3000';
  const [loading2, setLoading2] = useState(false);
  const [user, setUser] = useState(null);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  let userDetail = secureLocalStorage.getItem('LogObject');

  console.log("userDetail checkout==========", userDetail);

  // const location = useLocation();
  // const cart = location.state && location.state.cart ? location.state.cart : [];

  const location = useLocation();
  const cartFromLocationState = location.state && location.state.cart;

  const [cart, setCart] = useState([]);

  console.log("cart checkout", cart);
  console.log("cart checkout planid", cart[0] ? cart[0].id : '');

  // Load cart data from localStorage on component mount
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart'));
    console.log("storedCart", storedCart);
    if (storedCart) {
      setCart(storedCart);
    }
  }, []);

  // Update the cart state whenever the cart data changes
  useEffect(() => {
    if (cartFromLocationState) {
      setCart(cartFromLocationState);
      // Save cart data to localStorage whenever it changes
      localStorage.setItem('cart', JSON.stringify(cartFromLocationState));
    }
  }, [cartFromLocationState]);


  console.log("user=========", user);
  console.log("Cart=========", cart);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')

      script.src = src

      script.onload = () => {
        resolve(true)
      }

      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
  })

  const amount = cart[0] ? cart[0].planPrice : '';
  const currency = "INR";
  const receiptId = "qwsaq1";
  const plan_id = cart[0] ? cart[0].planProcessorID : '';
  const plan_name = cart[0] ? cart[0].planName : '';
  const userEmail = userDetail?.email;
  const userName = userDetail?.name;
  const purchaseFromUserID = userDetail?.userID;
  const period = cart[0] ? cart[0].planTypeID === 2 ? "monthly" : cart[0].planTypeID === 4 ? "yearly" : '' : '';

const paymentHandler = async (e) => {
  try {
      // Step 1: Create Subscription
      const subscriptionResponse = await fetch(`${API_URL}/razorpay/create-subscription`, {
          method: "POST",
          body: JSON.stringify({
              // Include subscription creation details here
              plan_id,
              total_count: 6,
              quantity: 1,
              start_at: 1735689600,
              expire_by: 1893456000,
              customer_notify: 1,
              amount,
              period,
              userEmail,
              purchaseFromUserID,
              addons: [],
              notes: {
                  notes_key_1: "Tea, Earl Grey, Hot",
                  notes_key_2: "Tea, Earl Grey… decaf."
              }
          }),
          headers: {
              "Content-Type": "application/json",
          },
      });
      const subscriptionData = await subscriptionResponse.json();
      console.log("subscriptionData==========", subscriptionData);
      const subscription_id = subscriptionData.subscription.id;
      const quantity = subscriptionData.subscription.quantity;

      // Step 2: Initialize Razorpay Payment
      var options = {
          key: "rzp_test_b4uxaMYcCrOf31", // Enter the Key ID generated from the Dashboard
          amount, // Amount is in currency subunits
          currency,
          name: "HawkGlide", // Your business name
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          subscription_id, // Include subscription ID here
          handler: async function (response) {
              // Step 3: Handle Payment Response
              const body = {
                  ...response,
                  amount, // Include amount in the request body
                  currency, // Include currency in the request body
                  receipt: receiptId, // Include receipt ID in the request body
                  subscription_id, // Include subscription ID in the request body
                  plan_id,
                  plan_name,
                  period,
                  userName,
                  userEmail,
                  purchaseFromUserID,
                  quantity
              };

              console.log("Body============", body);


              try {
                  // Step 4: Validate Payment
                  const validateRes = await fetch(`${API_URL}/razorpay/payment/validate`, {
                      method: "POST",
                      body: JSON.stringify(body),
                      headers: {
                          "Content-Type": "application/json",
                      },
                  });
                  const jsonRes = await validateRes.json();
                  console.log(jsonRes);

                  // Handle payment validation response accordingly
                  // alert(jsonRes.message);
                  navigate('/thankyou');
              } catch (error) {
                  console.error("Error in paymentHandler - Payment validation:", error);
                  // Handle error in payment validation, show error message to user, etc.
              }
          },
          prefill: {
              name: userName,
              email: userEmail,
              contact: "9769545949",
          },
          notes: {
              address: "Razorpay Corporate Office",
          },
          theme: {
              color: "#3399cc",
          },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
      e.preventDefault();
  } catch (error) {
      console.error("Error in paymentHandler:", error);
      // Handle error, show error message to user, etc.
  }
};

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    if (action === 'login') {
      const userString = searchParams.get('user');
      const userSuccess = searchParams.get('success');
      const userFailed = searchParams.get('failed');
      if (userFailed === 'true') {
        setFailed(true);
      }
      if (userSuccess === 'true') {
        setSuccess(true);
      }
      if (userString) {
        const userObject = JSON.parse(decodeURIComponent(userString));
        setUser(userObject);
        console.log("User details from URL ====", userObject);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (failed) {
      // toast.error('Failed to login please try with valid Gmail-ID!');
      navigate('/login');
      return;
    }
    if (user) {
      console.log("user google ==========", user);
      setUser(user);
      // toast.success('Logged In successfully!');
      navigate('/pricing');
    }
    else {
      if(success && user === undefined){
        // toast.error('Failed to login please try with valid Gmail-ID!');
        navigate('/login');
      }
    }
  }, [user, failed]);

  return (
    <>

      <div className="container py-5">
        <div className='main-section-form mt-4 pt-5'>
          
            <div className="row justify-content-center align-items-center">
            <div className="col-md-3 bg-white p-4 rounded-4" style={{opacity : 0.3, filter: 'blur(2px)',boxShadow : 'rgb(200, 200, 200) 0px 0px 2px' , marginRight : -50}} >
                    <div>
                    <img src={require('../images/seccret-room.png')} alt="Product 1" className='pb-2' style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                    <div>
                    
                    <p>My online presence has never been stronger. Highly recommend!</p>
                    <p className='font-weight-bold my-0'>Sarah Johnson</p>
                    <p className='fs-6 my-0'>Founder Secret Room</p>
                    <StarRating rating={5} />
                    </div>
                </div>
              <div className="col-md-6 position-relative z-index" style={{zIndex : 99}}>
              <div className='section-gray rounded-4 w-100 px-4 m-auto' style={{ padding: '35px' }}> 
                <h1 className="mb-0 branding-2 title-text pb-2 gradient-class font-weight-bold text-center">#1 most used<br/>
AI tool for Social Media Marketing</h1>
                <p className='fs-3 text-center branding-1'>Signup for 7 Days Free Trial</p>

                {/* Updated text for registration agreement */}
                <div className="registration-agreement text-center my-3">
                  By registering, you agree to our <Link to={'/terms-and-conditions'} className="branding-1 font-weight-bold">terms of use</Link>
                </div>
                {/* <div class="text-center">
                    <h2>Your Shopping Cart</h2>
                    <ul>
                        {cart.map((item) => (
                        <li key={item.id}>{item.item.name} - {item.item.amount / 100} - {item.id}</li>
                        ))}
                    </ul>
                </div> */}
                <div className="button-submit text-center" style={{ marginTop: 20 }}>
            <Button style={{ border: 'none', padding: '10px 40px' }} type="submit" onClick={paymentHandler} className='animated-button'>
              Proceed with Payment
            </Button>

            {/* <p className='my-4'>Already have an account? <Link to={'/signin'} className="font-weight-bold branding-1 text-decoration-none">Login</Link></p> */}
          </div>
              </div>
              </div>
              <div className="col-md-3 bg-white p-4 rounded-4" style={{opacity : 0.3, filter: 'blur(2px)', boxShadow : 'rgb(200, 200, 200) 0px 0px 2px' , marginLeft : -50}}>
                    <div>
                    <img src={require('../images/shubamMotivala.png')} alt="Product 2" className='pb-2' style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                    <div>
                    
                    <p>My online gfdgdf has never been stronger. Highly recommend!</p>
                    <p className='font-weight-bold my-0'>Khushbu Agarwal</p>
                    <p className='fs-6 my-0'>COO - Shubham Motiwala</p>
                    <StarRating rating={5} />
                    </div>
                </div>
            </div>
            
          </div>

          {/* Updated "Start your 7-Day Free Trial" button */}
          
        </div>
      
        <section className="callto-section py-5 bg-branding-2">
        <div className="container">
          {/* <div className="row align-items-center justify-content-center"> */}
            {/* Text on the left side */}
            <div className="w-63 m-auto text-center">
              <div className="text-container">
                <h3 className="title-text fs-1 py-2 mb-3 text-white">
                  <span className="font-weight-bold ">
                    Powerful AI tool for Social Media Management
                  </span>{" "}
                  that increases your Social Media Presence
                </h3>
                <div className="links-tab py-2 my-4">
                  <Link
                    to={"/"}
                    className="animated-button-2 bg-transparent border-white text-white"
                  >
                    Start your 7-Day Free Trial
                  </Link>
                  <Link
                    to={"/"}
                    className="animated-button-2 bg-transparent border-white text-white"
                    style={{ marginLeft: 15 }}
                  >
                    See Pricing Plans
                  </Link>
                </div>
                {/* Add any other text or components as needed */}
              </div>
            </div>

            {/* Image on the right side */}
            <div className="col-md-4 text-center">
              <div className="image-container">
                {/* <img
                    src={require('../images/wings.jpg')}
                    alt="Wings Image"
                    className="img-fluid" style={{height: 200, objectFit : 'contain'}}
                /> */}
              </div>
            </div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default Checkout;
