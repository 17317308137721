// InnerBlog.js

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import Rating from "react-rating-stars-component";
import { BlogData } from "../data/blog-data";
import { useLocation } from "react-router-dom";
import CallToAction from "../components/Call-to-action";
import { getPostByID } from "../data/actionMethods";

const InnerBlog = (props) => {
  const SERVER_BASE_URL = 'https://api.hawkglide.com';
  // const SERVER_BASE_URL = 'http://localhost:5001';
  const decodeHTMLEntities = (input) => {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent
      .replace(/&#9;/g, " ")
      .replace(/\s+/g, " ")
      .trim();
  };

  const [blogData, setBlogData] = useState([]);
  const [blogDescContent, setBlogDescContent] = useState([]);
  const [featuredImage, setFeaturedImage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const postSlug = location.pathname.split("/")[2];

  useEffect(() => {
      getPostByID(postSlug).then((res) => {
        console.log('Blog-Data==============', res);
          if (res.data.content) {
              if (postSlug !== res.data.content.postSlug) {
                  //Update slug
                  navigate(`/blog/${res.data.content.postSlug}`);
              }
              console.log('Blog-Data', res.data.content);

              setBlogData(res.data.content ? res.data.content : []);
              setFeaturedImage(res.data.content.postMeta ? JSON.parse(res.data.content.postMeta).featuredImage : {});
              const postMeta = res.data.content.postMeta ? JSON.parse(res.data.content.postMeta) : {};
              let decodedContent = postMeta.content ? decodeURIComponent(postMeta.content) : '';

              // Replace relative image paths with full paths
              decodedContent = decodedContent.replace(/src="\.\.\/\.\.\/(uploads\/public_images\/[^"]+)"/g, `src="${SERVER_BASE_URL}/$1"`);

              setBlogDescContent(decodedContent);
          }
      });
  }, [postSlug]);

  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row align-items-center pt-4">
            <div className="col-md-12 px-md-3 px-0">
              <h1 className="title-text branding-1 text-center my-4">
                {blogData.postName}
              </h1>
              {/* <div className="row">
                <p className="pb-0 mb-0 branding-1 text-center">
                  {" "}
                  <img
                    src={require("../images/check-edit.png")}
                    alt="Document"
                    className="img-fluid my-3"
                  />{" "}
                  Posted on 16th February, 2024
                </p>
              </div> */}
              {/* <div className='socials py-2 pt-2 pt-0 footer'>
              <a href="https://facebook.com/" target="_blank"><img style={{width:'44px'}} src={require('../images/facebook.png')} className="px-2" /></a>
              <a href="https://twitter.com/" target="_blank"><img style={{width:'44px'}} src={require('../images/twitter.png')} className="px-2" /></a>
              <a href="https://www.instagram.com/" target="_blank" rel="noopener"><img style={{width:'44px'}} src={require('../images/instagram.png')} className="px-2" /></a>
              <a href="https://www.linkedin.com/" target="_blank" rel="noopener"><img style={{width:'44px'}} src={require('../images/linkedin.png')} className="px-2 " /></a>
              
            </div> */}
            </div>
            <div className="col-md-12 px-md-3 px-0">
              <div className="blog-inner-banner">
                <img
                  style={{ minHeight: 300, maxHeight : 400, objectFit: "cover", width: "100%" }}
                  // src={require("../images/blog-image.jpg")}
                  src={`${SERVER_BASE_URL}${featuredImage}`}
                  alt="Blog Image"
                  className="img-fluid rounded-4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center py-4">
        <div className='socials footer'>
              <a href="https://facebook.com/" target="_blank"><img style={{width:'56px'}} src={require('../images/facebook-share.png')} className="px-2" /></a>
              <a href="https://twitter.com/" target="_blank"><img style={{width:'56px'}} src={require('../images/twitter-share.png')} className="px-2" /></a>
              <a href="https://www.instagram.com/" target="_blank" rel="noopener"><img style={{width:'56px'}} src={require('../images/instagram-share.png')} className="px-2" /></a>
              <a href="https://www.linkedin.com/" target="_blank" rel="noopener"><img style={{width:'56px'}} src={require('../images/linkedin-share.png')} className="px-2 " /></a>
              <a href="$" target="_blank" rel="noopener"><img style={{width:'56px'}} src={require('../images/whatsapp-share.png')} className="px-2 " /></a>
              
            </div>
        </div>
        <div className="nner-content m-auto pb-0" style={{width : '40%'}}>
        
        <div className="blog-content" style={{ maxWidth: '100%' }} dangerouslySetInnerHTML={{ __html: blogDescContent }}></div>
          {/* <h2>
            The Power of AI: How Hawk Glide is Transforming Social Media
            Management
          </h2>

          <p>
            In the rapidly evolving landscape of social media, where trends come
            and go in the blink of an eye, businesses face the constant
            challenge of staying relevant and engaging with their audience
            effectively. With the rise of artificial intelligence (AI), however,
            managing social media has taken a significant leap forward. One such
            groundbreaking tool leading the charge is Hawk Glide,
            revolutionizing the way businesses navigate the complexities of
            social media management.
          </p>

          <h3 classname="fs-4 font-weight-bold" style={{ marginTop: 30 }}>
            Understanding the Landscape
          </h3>

          <p>
            Social media platforms have become integral to modern marketing
            strategies, offering unparalleled opportunities for brands to
            connect with their target audience, build relationships, and drive
            conversions. However, with the sheer volume of content being
            generated every second, managing multiple platforms while
            maintaining consistency and relevance can be daunting.
          </p>

          <p>
            Traditional social media management often involves manual processes,
            from content creation and scheduling to monitoring engagement and
            analyzing performance metrics. Not only is this time-consuming, but
            it also leaves room for human error and may not harness the full
            potential of available data.
          </p>

          <h3 classname="fs-4 font-weight-bold" style={{ marginTop: 30 }}>
            Enter Hawk Glide: The AI-Powered Solution
          </h3>

          <p>
            Hawk Glide represents a paradigm shift in social media management,
            harnessing the power of AI to streamline processes, enhance
            engagement, and drive results. Leveraging advanced algorithms and
            machine learning capabilities, Hawk Glide offers a comprehensive
            suite of features designed to empower businesses of all sizes.
          </p>

          <h3 classname="fs-4 font-weight-bold" style={{ marginTop: 30 }}>
            Automated Content Curation and Scheduling
          </h3>

          <p>
            Gone are the days of manually scouring the internet for content
            ideas and scheduling posts across different platforms. Hawk Glide's
            AI engine analyzes vast amounts of data in real-time to identify
            trending topics, relevant hashtags, and engaging content formats
            tailored to each brand's audience. By automating content curation
            and scheduling, businesses can maintain a consistent presence on
            social media without the hassle.
          </p>

          <h3 classname="fs-4 font-weight-bold" style={{ marginTop: 30 }}>
            Intelligent Audience Insights
          </h3>

          <p>
            Understanding the audience is key to crafting compelling content and
            driving meaningful interactions. Hawk Glide goes beyond basic
            demographic data, utilizing AI-driven analytics to uncover
            actionable insights into audience behavior, preferences, and
            sentiment. From identifying peak engagement times to predicting
            content performance, businesses gain valuable intelligence to
            optimize their social media strategy effectively.
          </p>

          <h3 classname="fs-4 font-weight-bold" style={{ marginTop: 30 }}>
            Dynamic Engagement Optimization
          </h3>

          <p>
            Engagement is the lifeblood of social media success, but manually
            managing interactions across platforms can be overwhelming. Hawk
            Glide's AI-powered engagement tools leverage natural language
            processing (NLP) and sentiment analysis to prioritize and respond to
            comments, messages, and mentions in real-time. By automatically
            detecting and flagging relevant conversations, businesses can foster
            genuine connections and mitigate potential crises swiftly.
          </p>

          <h3 classname="fs-4 font-weight-bold" style={{ marginTop: 30 }}>
            Performance Monitoring and Optimization
          </h3>

          <p>
            Measuring the impact of social media efforts is essential for
            refining strategies and maximizing ROI. Hawk Glide provides
            comprehensive performance analytics, leveraging AI to track key
            metrics such as reach, engagement, and conversion rates across
            multiple channels. Through actionable insights and predictive
            analytics, businesses can identify what's working, what's not, and
            adapt their approach accordingly for continuous improvement.
          </p>

          <h3 classname="fs-4 font-weight-bold" style={{ marginTop: 30 }}>
            The Future of Social Media Management
          </h3>

          <p>
            As AI continues to evolve, so too will the capabilities of tools
            like Hawk Glide, shaping the future of social media management in
            profound ways. From personalized content recommendations to
            predictive modeling and automated ad optimization, the possibilities
            are limitless.
          </p>

          <p>
            However, while AI offers unprecedented efficiency and scalability,
            it's essential to recognize the importance of human oversight and
            creativity in the social media landscape. While Hawk Glide can
            automate mundane tasks and augment decision-making, human intuition
            and empathy remain invaluable for crafting authentic brand
            experiences and fostering genuine connections with audiences.
          </p>

          <h3 classname="fs-4 font-weight-bold" style={{ marginTop: 30 }}>
            Conclusion
          </h3>

          <p>
            In an era defined by digital transformation and data-driven
            insights, Hawk Glide stands at the forefront of innovation,
            empowering businesses to harness the full potential of social media
            with AI-driven precision. By automating repetitive tasks, unlocking
            actionable insights, and optimizing engagement, Hawk Glide enables
            brands to stay ahead of the curve and drive meaningful results in an
            increasingly competitive landscape.
          </p> */}
        {/* </div> */}
        {/* <div className="container my-5 mb-0">
        <div className='links-tab py-2'>
                <Link to={'/'} className="button-branding-2 fs-6">
                The Power of AI
                </Link>
                <Link to={'/'} className="button-branding-2 fs-6" style={{marginLeft : 15 , float: 'right'}}>
                The Power of AI
                </Link>
                </div>
        </div> */}
        

        <div className="links-tab pt-3 quick-access my-4 mb-0">
          <div className="row">
            <div className="col-md-6 px-md-3 px-0">
              <div
                className="row align-items-center section-gray rounded-5 mx-0 py-2 mt-4"
                style={{ margin: 0, maxWidth: "95%" }}
              >
                <div className="col-md-3">
                  <img
                    style={{ width: "65px" }}
                    src={require("../images/archive.png")}
                    alt="Blog Image"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-9" style={{ paddingLeft: 0 }}>
                  <p className="font-weight-bold branding-1 mb-0" style={{fontSize: 14}}>
                    The AI-Powered Evolution of Social Media Banners
                  </p>
                  <p className="branding-1 mb-0 py-2" style={{fontSize: 12}}>
                    {" "}
                    <img
                      style={{ marginRight: 3, width: 14 }}
                      src={require("../images/check-edit.png")}
                      alt="Document"
                      className="img-fluid"
                    />{" "}
                    Posted on 16th February, 2024
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-md-3 px-0">
              <div
                className="row align-items-center section-gray rounded-5 py-2 my-4"
                style={{ margin: 0, maxWidth: "95%" , float: 'right' }}
              >
                <div className="col-md-3">
                  <img
                    style={{ width: "65px" }}
                    src={require("../images/archive.png")}
                    alt="Blog Image"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-9" style={{ paddingLeft: 0 }}>
                  <p className="font-weight-bold branding-1 mb-0" style={{fontSize: 14}}>
                    The AI-Powered Evolution of Social Media Banners
                  </p>
                  <p className="branding-1 mb-0 py-2" style={{fontSize: 12}}>
                    {" "}
                    <img
                      style={{ marginRight: 3, width: 14 }}
                      src={require("../images/check-edit.png")}
                      alt="Document"
                      className="img-fluid"
                    />{" "}
                    Posted on 16th February, 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      </section>

      

     
      <section className="container padding-bottom">
      

        <section className="call-action-dark py-4 mb-5 bg-blue-dark rounded-4">
          <div className="text-center p-4">
            <h2 className="text-white text-center title-text">
              <span className="font-weight-bold text-white">
                An Amazing AI-based Social Media Manager
              </span>
              <br /> is here to support your journey to social media success
            </h2>
            <div className="links-tab py-3 my-4 mb-0">
              <Link
                to={"/signup"}
                className="animated-button-2"
              >
                <span>Start your 7-Day Free Trial</span>
              </Link>
              <Link
                to={"/pricing"}
                className="animated-button-2"
                style={{ marginLeft: 15 }}
              >
                <span>See Pricing Plans</span>
              </Link>
            </div>
          </div>
        </section>
      </section>
     <CallToAction />
    </>
  );
};

export default InnerBlog;
