import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Rating from "react-rating-stars-component";
import Faq from "../components/Faq";
import CallToAction from "../components/Call-to-action";

const RefundPolicy = () => {
  return (
    <>
      <section className="terms-section py-5">
        <div className="container">
          <div className="text-center mb-5 mt-5">
            <h1 className="mb-0 text-title gradient-class">
              <span className="branding-2 font-weight-bold">
                Privacy Policy
              </span>
            </h1>
          </div>
          <div
            className="bg-white rounded-4 p-5 w-75 m-auto"
            style={{ boxShadow: "0px 0px 4px #4242421c" }}
          >
            <div className="terms-content">
              <p>At Hawk Glide, we aim to provide the best service possible to our customers. If you are not entirely satisfied with your purchase of our AI-powered social media posting plans, we offer a refund policy to ensure your satisfaction.</p>
              <ol>
                <li>
                  <strong>Refund Eligibility</strong>
                  <p>To be eligible for a refund, the following conditions must be met:</p>
                  <p>1. Cancellation within Trial Period: If you cancel your subscription plan within the specified trial period (if applicable), you will be eligible for a full refund of any fees paid.</p>
                  <p>2. Service Dissatisfaction: If you are dissatisfied with our service for any reason, you may request a refund within 7 days of the original purchase date.</p>
                </li>

                <li>
                  <strong>Refund Process</strong>
                  <p>To initiate a refund, please contact our customer support team at <a href="mailto:hello@hawkglide.com">hello@hawkglide.com</a> with your order details and reason for the refund request. Our team will review your request and guide you through the refund process.</p>
                  <p>Upon approval of your refund request, we will issue a refund to the original method of payment within 7 business days. Please note that it may take some time for the refund to be reflected in your account, depending on your payment provider.</p>
                </li>

                <li>
                  <strong>Non-Refundable Items</strong>
                  <p>Certain items are not eligible for refunds, including:</p>
                  <p>Service Usage: Any portion of the service that has already been utilized or accessed during the subscription period is non-refundable.</p>
                  <p>Customized Plans: Plans that have been customized or tailored to your specific needs may not be eligible for a full refund, but partial refunds may be considered on a case-by-case basis.</p>
                </li>

                <li>
                  <strong>Changes to This Refund Policy</strong>
                  <p>We reserve the right to update or change our Refund Policy at any time. Any changes will be posted on this page with an updated effective date.</p>
                </li>

                <li>
                  <strong>Contact Us</strong>
                  <p>If you have any questions or concerns about our Refund Policy, please don't hesitate to contact us at <a href="mailto:hello@hawkglide.com">hello@hawkglide.com</a>. Our customer support team is here to assist you.</p>
                </li>

              </ol>
            </div>
          </div>
        </div>
      </section>
      <CallToAction />
    </>
  );
};

export default RefundPolicy;
